@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&family=Poppins:wght@300;400;600&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  background-color: #f8f4f0;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.domain-container {
  background: #ffffff;
  border-radius: 15px;
  padding: 30px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: slideUp 1.2s ease-in-out;
}

.domain-header h1 {
  font-family: 'Playfair Display', serif;
  font-size: 2.5em;
  color: #2a2a2a;
  margin: 0;
  letter-spacing: 1px;
}

.domain-content {
  margin-top: 20px;
}

.domain-name {
  font-size: 2em;
  font-weight: 600;
  color: #333333;
  margin: 20px 0;
  letter-spacing: 0.5px;
  background: linear-gradient(90deg, #9b9b9b, #6c63ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.domain-info {
  font-size: 1.1em;
  color: #555;
  margin-bottom: 30px;
  line-height: 1.6;
}

.contact-section h3 {
  font-size: 1.5em;
  color: #6c63ff;
  margin-bottom: 10px;
}

.contact-link {
  color: #6c63ff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.contact-link:hover {
  color: #333333;
}

.domain-footer {
  margin-top: 30px;
  font-size: 0.9em;
  color: #aaa;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
